import axios from 'axios';
import { getApiUrl } from '../utils/helpers';

const API_URL = getApiUrl();

const authApi = axios.create({
  baseURL: API_URL,
});




export const login = async (email, password) => {
  try {
    const response = await authApi.post('/auth/login', { email, password });
    if (response.data.access_token && response.data.refresh_token) {
      return { 
        success: true, 
        user: response.data.user,
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token
      };
    } else {
      return { success: false, message: 'Login failed' };
    }
  } catch (error) {
    return { success: false, message: error.response?.data?.message || 'An error occurred during login' };
  }
};

export const refreshToken = async (refresh_token) => {
  try {
    const response = await authApi.post('/auth/refresh', {}, {
      headers: { Authorization: `Bearer ${refresh_token}` }
    });
    if (response.data.access_token && response.data.refresh_token) {
      return { success: true, ...response.data };
    } else {
      return { success: false, message: 'Token refresh failed' };
    }
  } catch (error) {
    return { success: false, message: error.response?.data?.message || 'An error occurred during token refresh' };
  }
};

export const register = async (email, password) => {
  try {
    const response = await authApi.post('/auth/signup', { email, password });
    if (response.data.success) {
      return {
        success: true,
        user: response.data.user,
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token
      };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (error) {
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred during registration' 
    };
  }
};

export const logout = async () => {
  try {
    const token = localStorage.getItem('token');
    if (token) {
      await authApi.delete('/auth/logout', {
        headers: { Authorization: `Bearer ${token}` }
      });
    }
  } catch (error) {
    console.error('Error during logout:', error);
  } finally {
    localStorage.removeItem('token');
  }
};


export const verifyEmail = async (token) => {
  try {
    const response = await authApi.get(`/auth/verify-email/${token}`);
    if (response.data.message) {
      return { success: true, message: response.data.message };
    } else {
      return { success: false, message: 'Email verification failed' };
    }
  } catch (error) {
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred during email verification' 
    };
  }
};

export const deleteAccount = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await authApi.delete('/auth/delete-account', {
      headers: { Authorization: `Bearer ${token}` }
    });
    if (response.status === 200) {
      return { success: true, message: 'Account deleted successfully' };
    } else {
      return { success: false, message: 'Failed to delete account' };
    }
  } catch (error) {
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred while deleting the account' 
    };
  }
};

export const requestPasswordReset = async (email) => {
  try {
    const response = await authApi.post('/auth/request-reset', { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await authApi.post('/auth/reset-password', { token, newPassword });
    return response.data;
  } catch (error) {
    throw error;
  }
};
