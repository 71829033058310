
// Validate API Key format
export const isValidApiKey = (apiKey) => {
    // This is a simple check. Adjust the regex based on your actual API key format
    const apiKeyRegex = /^[A-Za-z0-9]{32}$/;
    return apiKeyRegex.test(apiKey);
  };
  
  // Format query for submission
  export const formatQuery = (query, maxLength = 500) => {
    // Trim whitespace and limit query length
    return query.trim().slice(0, maxLength);
  };
  
  // Standardize error messages
  export const formatErrorMessage = (error) => {
    if (typeof error === 'string') {
      return error;
    }
    if (error.response && error.response.data && error.response.data.message) {
      return error.response.data.message;
    }
    return 'An unexpected error occurred. Please try again.';
  };
  
  // Example of a debounce function
  export const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  export const getBaseUrl = () => {
    const { protocol, hostname, port } = window.location;
    return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  };
  
  export const getApiUrl = () => {
    const baseUrl = getBaseUrl();
    
    // Check if we're in development mode
    if (process.env.NODE_ENV === 'development') {
      // Assuming your API is running on port 5000 in development
      return baseUrl.replace(/:\d+/, ':5000');
    }
    
    // In production, you might want to use a specific API URL or path
    // return process.env.REACT_APP_API_URL || `${baseUrl}/api`;
    return '/api';
  };
