import React, { createContext, useState, useEffect, useCallback } from 'react';
import { login as apiLogin, register as apiRegister, logout as apiLogout, refreshToken as apiRefreshToken } from '../services/auth';
import { fetchUserData as apiFetchUserData } from '../services/api'

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserData = useCallback(async () => {
    try {
      const data = await apiFetchUserData();
      return data;
    } catch (err) {
      setError('Failed to fetch user data');
      throw err;
    }
  }, []);



  const handleRefreshToken = useCallback(async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      setUser(null);
      setLoading(false);
      return;
    }

    try {
      const result = await apiRefreshToken(refreshToken);
      if (result.success) {
        localStorage.setItem('token', result.access_token);
        localStorage.setItem('refreshToken', result.refresh_token);
        setUser(result.user);
      } else {
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
      }
    } catch (err) {
      console.error('Error refreshing token:', err);
      setUser(null);
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    handleRefreshToken();
  }, [handleRefreshToken]);

  const handleLogin = async (email, password) => {
    setError(null);
    try {
      const result = await apiLogin(email, password);
      if (result.success) {
        localStorage.setItem('token', result.access_token);
        localStorage.setItem('refreshToken', result.refresh_token);
        setUser(result.user);
        return true;
      } else {
        setError(result.message);
        return false;
      }
    } catch (err) {
      setError('An unexpected error occurred during login.');
      return false;
    }
  };

  const handleLogout = useCallback(async () => {
    try {
      await apiLogout();
    } catch (err) {
    } finally {
      setUser(null);
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    }
  }, []);

  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        await handleRefreshToken();
      } catch (err) {
        localStorage.removeItem('token');
        setUser(null);
      }
    }
    setLoading(false);
  }, [handleRefreshToken]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);


  const handleRegister = async (email, password) => {
    setError(null);
    try {
      const result = await apiRegister(email, password);
      if (result.success) {
        return { success: true, message: result.message };
      } else {
        setError(result.message);
        return { success: false, message: result.message };
      }
    } catch (err) {
      const errorMessage = 'An unexpected error occurred during registration.';
      setError(errorMessage);
      return { success: false, message: errorMessage };
    }
  };

  useEffect(() => {
    if (user) {
      const refreshInterval = setInterval(handleRefreshToken, 14 * 60 * 1000); // Refresh every 14 minutes
      return () => clearInterval(refreshInterval);
    }
  }, [user, handleRefreshToken]);


  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
    </div>
  );

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        login: handleLogin, 
        register: handleRegister,
        logout: handleLogout, 
        refreshToken: handleRefreshToken,
        fetchUserData,
        error, 
        setError 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};